import React from "react";
import sound from "./assets/images/sound.gif";
import logo from "./assets/images/logo.png";
import logo1 from "./assets/images/logo-1.png";

const Header = () => {
  return (
    <div className="header container d-flex flex-column align-items-center justify-content-center text-center h-100">
      <img src={logo1} alt="" />
      {/* <h1>
        LullabAI
        <img src={sound} alt="" />
      </h1> */}
    </div>
  );
};

export default Header;

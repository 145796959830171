// src/VoiceTextForm.js
import React, { useState, useEffect } from "react";
import { ReactMic } from "react-mic";
import axios from "axios";
import cloneicon from "./assets/images/cloneicon.svg";
import texticon from "./assets/images/texticon.svg";
import trainicon from "./assets/images/trainicon.svg";
import micicon from "./assets/images/micicon.svg";

const VoiceTextForm = () => {
  const [recording, setRecording] = useState(false);
  const [blobObject, setBlobObject] = useState(null);
  const [voiceList, setVoiceList] = useState([]);
  const [text, setText] = useState("");
  const [dropdownValue, setDropdownValue] = useState("");
  const [audioURL, setAudioURL] = useState(null);
  const [audioURLNew, setAudioURLNew] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingNew, setLoadingNew] = useState(false);
  const [key_, setApiKey] = useState(
    "sk_de491e7598570b82fce905eeac2f4a84d7077ddedd6c4ce2"
  );

  useEffect(() => {
    getVoiceList(key_);
  }, []);

  // const startRecording = () => {
  //   setRecording(true);
  //   setAudioURL(null);
  // };

  // const stopRecording = () => {
  //   setRecording(false);
  // };

  const onRecording = () => {
    if (recording) {
      setRecording(false);
    } else {
      setRecording(true);
      setAudioURL(null);
    }
  };

  const onData = (recordedBlob) => {
    //  console.log("real-time data", recordedBlob);
  };

  const onStop = (recordedBlob) => {
    setBlobObject(recordedBlob.blob);
    setAudioURL(URL.createObjectURL(recordedBlob.blob));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!blobObject) {
      alert("Please record your voice first!");
      return;
    }
    setAudioURL(null);

    const formData = new FormData();
    formData.append("files", blobObject, "audio.wav");
    formData.append("name", text);
    const obj = Object.fromEntries(formData);

    try {
      setLoadingNew(true);
      await axios.post("https://api.elevenlabs.io/v1/voices/add", obj, {
        headers: {
          "Content-Type": "multipart/form-data",
          "xi-api-key": key_,
        },
      });
      await getVoiceList(key_);
      setLoadingNew(false);
      alert("Saved");
      // setVoiceList(response.data);
    } catch (error) {
      setLoadingNew(false);
      console.error("Error submitting data:", error);
      alert("Failed to submit data.");
    }
  };

  async function getVoiceList(params) {
    try {
      const response = await axios.get("https://api.elevenlabs.io/v1/voices", {
        headers: {
          "Content-Type": "multipart/form-data",
          "xi-api-key": params,
        },
      });
      let clonedVoices = response.data.voices;
      clonedVoices = clonedVoices.filter(
        (voice) => voice.category === "cloned"
      );
      setVoiceList(clonedVoices);
    } catch (error) {
      console.error("Error submitting data:", error);
      alert("Failed to submit data.");
    }
  }

  async function handleSubmitTextToVoice(e) {
    e.preventDefault();
    try {
      setLoading(true);
      const formdata = new FormData(e.target);
      const obj = Object.fromEntries(formdata);
      const url = `https://api.elevenlabs.io/v1/text-to-speech/${obj.voice_id}`; // Replace with your API URL
      const headers = {
        "Content-Type": "application/json",
        "xi-api-key": key_,
      };

      const data = {
        text: obj.text,
        model_id: "eleven_turbo_v2_5",
        voice_settings: {
          stability: 0.5,
          similarity_boost: 0.5,
        },
      };

      const response = await axios.post(url, data, {
        headers,
        responseType: "blob", // Important to set response type as blob to handle binary data
      });

      const blob = new Blob([response.data], { type: "audio/mpeg" });
      const downloadUrl = URL.createObjectURL(blob);
      setAudioURLNew(downloadUrl);
      // setLoading(false);
      setLoading(false);
      //setAudioURL(response);
    } catch (error) {
      console.error("Error submitting data:", error.message);
      alert("Failed to submit data.");
      setLoading(false);
    }
  }

  async function getVoiceHistory(id) {}

  return (
    <div className="container mt-5">
      {/* Tabs */}
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        <li className="nav-item" role="presentation">
          <button
            className="nav-link d-flex align-items-center active"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home-tab-pane"
            type="button"
            role="tab"
            aria-controls="home-tab-pane"
            aria-selected="true"
          >
            <img src={texticon} alt="" />
            Text To Speech
          </button>
        </li>

        <li className="nav-item" role="presentation">
          <button
            className="nav-link d-flex align-items-center"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile-tab-pane"
            type="button"
            role="tab"
            aria-controls="profile-tab-pane"
            aria-selected="false"
          >
            <img src={cloneicon} alt="" />
            Voice Cloning
          </button>
        </li>

        <li className="nav-item" role="presentation">
          <button
            className="nav-link d-flex align-items-center"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#train-tab-pane"
            type="button"
            role="tab"
            aria-controls="train-tab-pane"
            aria-selected="false"
          >
            <img src={trainicon} alt="" />
            Train Model
          </button>
        </li>
      </ul>

      {/* Tabs Content */}
      <div className="tab-content" id="myTabContent">
        {/* Text To Speech */}
        <div
          className="tab-pane fade show active"
          id="home-tab-pane"
          role="tabpanel"
          aria-labelledby="home-tab"
          tabIndex="0"
        >
          <div className="card shadow-lg bg-white mt-3 mb-5">
            <div className="card-body">
              {audioURLNew && (
                <div className="text-center mb-3 d-md-flex d-block align-items-center">
                  <audio controls src={audioURLNew} className=""></audio>
                  <a
                    href={audioURLNew}
                    download="recorded-audio.wav"
                    className="btn btn-dark"
                  >
                    Download Audio
                  </a>
                </div>
              )}
              <form onSubmit={handleSubmitTextToVoice}>
                <div className="form-group">
                  {/* <label htmlFor="text">Enter Text:</label> */}
                  <textarea
                    id="text"
                    className="form-control outline-none"
                    rows="7"
                    //  value={text}
                    defaultValue="Type anything and turn it into speech"
                    onClick={(e) => (e.target.value = "")}
                    // onChange={(e) => setText(e.target.value)}
                    name="text"
                  />
                </div>

                <div className="d-md-flex d-block justify-content-between align-items-center mt-5 group-row">
                  <div className="form-group">
                    {/* <label htmlFor="dropdown">Choose Your Voice:</label> */}
                    <select
                      id="dropdown"
                      className="form-control"
                      value={dropdownValue}
                      onChange={(e) => setDropdownValue(e.target.value)}
                      name="voice_id"
                      aria-placeholder="Choose Your Voice"
                    >
                      <option value="">Choose Your Voice</option>
                      {voiceList.map((v, i) => (
                        <option key={i} value={v.voice_id}>
                          {v.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-dark btn-block mt-4"
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Generate Speech"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Voice Cloning */}
        <div
          className="tab-pane fade"
          id="profile-tab-pane"
          role="tabpanel"
          aria-labelledby="profile-tab"
          tabIndex="0"
        >
          <div className="card shadow-lg mt-3 mb-5 bg-white">
            <div className="card-body">
              {/* <h2 className="card-title text-center mb-4">Record Your Voice</h2> */}
              {audioURL && (
                <div className="text-center mb-3 d-md-flex d-block align-items-center">
                  <audio controls src={audioURL} className=""></audio>
                  <a
                    href={audioURL}
                    download="recorded-audio.wav"
                    className="btn btn-dark"
                  >
                    Download Audio
                  </a>
                </div>
              )}

              <div className="text-center">
                <ReactMic
                  record={recording}
                  className="sound-wave"
                  onStop={onStop}
                  onData={onData}
                  strokeColor="#6943FC"
                  backgroundColor="#fff"
                  height={100}
                  width={800}
                />
              </div>

              <div className="text-center px-5 mb-4">
                Enable mic access, record yourself and generate new voices
              </div>

              <div className="text-center mb-3">
                <div className="d-md-flex d-inline-block align-items-center justify-content-center gap-2">
                  <button
                    onClick={onRecording}
                    className="btn btn-dark"
                    type="button"
                  >
                    {recording ? "Stop Recording" : "Start Recording"}
                    <img src={micicon} alt="" />
                  </button>
                  {/* <button
                    onClick={stopRecording}
                    className="btn btn-dark"
                    type="button"
                    disabled={recording ? false : true}
                  >
                    Stop Recording
                  </button> */}
                </div>

                <form onSubmit={handleSubmit}>
                  <div className="d-md-flex d-block align-items-center justify-content-between  mt-5 group-row">
                    <div className="form-group">
                      {/* <label htmlFor="text">Enter Your Voice Name:</label> */}
                      <input
                        id="text"
                        className="form-control"
                        rows="3"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        required
                        placeholder="Name your Voice Model"
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-dark btn-block mt-4"
                      disabled={loadingNew}
                    >
                      {loadingNew ? "Saving" : "Save Voice Model"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        {/* Train Model */}
        <div
          className="tab-pane fade"
          id="train-tab-pane"
          role="tabpanel"
          aria-labelledby="train-tab"
          tabIndex="0"
        >
          <div className="card shadow-lg mt-3 mb-5 bg-white">
            <div className="card-body">
              {/* <h2 className="card-title text-center mb-4">Fetch By Voice</h2> */}
              <div>
                {voiceList.map((v, i) => (
                  <p
                    key={i}
                    value={v.voice_id}
                    className="btn btn-md me-3 my-md-3 mb-2 btn-dark"
                    onClick={() => getVoiceHistory()}
                  >
                    {v.name}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoiceTextForm;

// src/App.js
import React from "react";
import VoiceTextForm from "./VoiceTextForm";
import Header from "./Header";

function App() {
  return (
    <div className="App page-container">
      <Header></Header>
      <VoiceTextForm />
    </div>
  );
}

export default App;
